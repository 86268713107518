import React, { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

export default function ExpertiseAccordion() {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <React.Fragment>
      <div className="text-left">
        <Accordion open={open === 17}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(17)}
          >
            General Corporate and Commercial Law
          </AccordionHeader>
          <AccordionBody className="text-white">
            Coghlan, Welsh & Guest&apos;s attorneys provide services on various
            aspects of commercial and corporate law including drafting, consumer
            law advice, contracts, debt recovery(including foreclosures),
            agriculture, banking law, insurance law, taxation law, commercial
            mergers, acquisitions, investment, exchange control, tax law,
            finance (corporate, project and structured), franchising, insolvency
            (judicial management, liquidation, sequestrations, compromise
            arrangements creditor protection), licensing (trading/shop and
            liquor including statutory compliance), due diligence audits,
            employee share incentive schemes, listings (private and public)
            company law, company registrations, competition law.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 1}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(1)}
          >
            Banking and Financial Services
          </AccordionHeader>
          <AccordionBody className="text-white">
            Coghlan Welsh & Guest has a well-defined financial institution
            practice with a thorough understanding of the banking and finance
            regulatory framework in Zimbabwe and internationally. Disciplines
            include acquisition finance, asset finance, cross-border finance,
            debt capital markets, export credit finance and project finance. The
            practice advises on the full spectrum of corporate and commercial
            work including commercial, regulatory and dispute resolution
            requirements of the banking industry, long term and short-term
            insurers, reinsurers, and their intermediaries. Our team members are
            part of the International Bar Association and regularly attend
            global conferences and seminars to keep up to date with trends
            affecting the regional and the global capital markets.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 2}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(2)}
          >
            Mergers & Acquisitions
          </AccordionHeader>
          <AccordionBody className="text-white">
            Coghlan Welsh & Guest provides advice on mergers and acquisitions,
            corporate, compliance (including taxation and competition issues),
            commercial drafting litigation and intellectual property support to
            a wide-ranging client base. The firm has legal Practitioners who in
            addition to legal training also have further training and
            qualifications in business, commercial and corporate law. The
            commercial and corporate law teams offer effective, appropriate, and
            efficient advice backed by a well stocked and up to date library,
            and research resource.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 3}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(3)}
          >
            Natural Resources & Renewable Energy
          </AccordionHeader>
          <AccordionBody className="text-white">
            Coghlan Welsh & Guest has an energy, mining, and commodities
            practice, representing lenders, developers, and sponsors in
            significant projects in Zimbabwe. The Practice has been involved in
            power projects in the country and has represented many local
            regional and international companies in drafting, review of
            agreements and contracts and due diligence reports. The firm has
            been involved in the mining sector for several years, advising on
            mining rights and laws, buying, selling, financing, and developing
            projects in the most important mining jurisdictions. The team
            advises on all matters pertaining to mining and minerals resource,
            oil and gas, including corporate and transactional, finance,
            environmental, tax, regulatory, health and safety and litigation and
            dispute resolution.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 4}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(4)}
          >
            Technology
          </AccordionHeader>
          <AccordionBody className="text-white">
            The Practice has several years of collective experience in the
            telecoms and technology industries, offering clients end-to-end
            solutions including commercial, litigation, regulatory and
            competition skills all within the telecoms, media, and technology
            industries. Its diverse client base includes technology companies,
            telecommunications operators, media houses, financial institutions,
            mining houses and ecommerce businesses.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 5}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(5)}
          >
            Corporate Rescue, Insolvency and Debt Restructuring
          </AccordionHeader>
          <AccordionBody className="text-white">
            Coghlan, Welsh & Guest’s insolvency practice covers all aspects of
            liquidations, estate sequestrations and surrender, judicial
            managements and schemes of arrangements and offers of compromise.
            The firm has been involved and advised in the liquidations and
            judicial managements of numerous entities in Zimbabwe including
            banking institutions and companies both listed and private.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 6}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(6)}
          >
            Conveyancing and Securities
          </AccordionHeader>
          <AccordionBody className="text-white">
            The firm has a team that specializes in conveyancing and property
            law. Our firm is well placed to attend to conveyancing work i.e.
            transfers, registration and cancellation of mortgage bonds and other
            securities, sectional title, subdivisions and consolidations. The
            firm has been involved in many development schemes including staff
            loan schemes for big corporations, banks, and similar organizations.
            The conveyancing fees are regulated by the Tariff set by the Law
            Society of Zimbabwe.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 7}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(7)}
          >
            Intellectual Property
          </AccordionHeader>
          <AccordionBody className="text-white">
            The firm offers services in intellectual property registration
            (locally, regionally, and internationally), advisory, assignment and
            cession of intellectual property, management, and maintenance of
            intellectual property portfolios on behalf of clients. The Practice
            deals in all intellectual law aspects namely patents, trademarks,
            copyright, industrial designs, and geographical indications. The
            practice has established relations with local, regional, and
            international Intellectual Property law practitioners. We also have
            functional relations with WIPO, ARIPO and ZIPO and our teams
            regularly attend seminars and workshops with these organizations.
            Our intellectual Property Law team is well equipped with many years
            of collective experience.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 8}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(8)}
          >
            Estate Planning and Administration
          </AccordionHeader>
          <AccordionBody className="text-white">
            The firm operates the Harare Board of Executors (Private) Limited
            which provides advisory services in estate planning related matters
            including wills, trusts, donations, registration and administration
            of estates and executor services.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 9}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(9)}
          >
            Labour and Employment Law
          </AccordionHeader>
          <AccordionBody className="text-white">
            The firm assists companies with all their employment law related
            matters including contracts of employment drafting and reviews,
            employment relationship, industrial relations, disciplinary
            procedures, human resources policy formulation and advise,
            collective action negotiations and bargaining, termination
            proceedings, retrenchment and dispute resolution and employee share
            incentive schemes. Our team has many years of experience in labor
            litigation and alternative dispute resolution, drafting of codes of
            conduct, and advising clients on compliance issues including
            establishing policy guidelines, client training and education. The
            team draws on a wide base of skills and expertise at all levels of
            experience and seniority to provide technically correct legal advice
            that makes business sense.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 10}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(10)}
          >
            Dispute Resolution
          </AccordionHeader>
          <AccordionBody className="text-white">
            The firm handles civil litigation in the Supreme Court, High Court,
            Labour Court, Administrative Court and Magistrates Court using both
            trial and motion proceedings. The firm also has specialized
            experience and expertise in the practice of local and international
            dispute resolution with the firm having represented its clients
            against adversaries represented by the world’s biggest corporate law
            firms. The firm has successfully represented clients across most
            industries including but not limited to technical disputes in the
            renewable energy sector, construction and engineering, mining,
            finance, infrastructure development and transportation industries.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 11}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(11)}
          >
            Matrimonial and Family Law
          </AccordionHeader>
          <AccordionBody className="text-white">
            The firm advises and litigates on all matrimonial and family issues
            in all courts. We have handled high profile cases most of which are
            reported in the Zimbabwe law reports and we are very proud of the
            contribution we have made in the development on the law in this
            area.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 12}>
          <AccordionHeader
            className="text-white text-left"
            onClick={() => handleOpen(12)}
          >
            Criminal Law
          </AccordionHeader>
          <AccordionBody className="text-white">
            The firm represents corporate and individuals in a wide range of
            criminal matters including criminal investigations, as well as
            matters before the Magistrate’s Court, High Court, and Supreme Court.
          </AccordionBody>
        </Accordion>
      </div>
    </React.Fragment>
  );
}
